import { createContext, FC, useCallback, useEffect, useState } from "react";
import { LocalAudioTrack, LocalVideoTrack } from "twilio-video";
import { useVideoContext } from "../../hooks/useVideoContext/useVideoContext";
import { ReactElementProps } from "../../types";
import { LoadFromUrlPayload, PlaybackContextType, PlaybackState } from "../../types/playback-provider";
import { AudioElement, VideoElement } from "../../types/twilio-video";
import { v4 as uuidv4 } from "uuid";
import { AppendVideoElement } from "../../functions/AppendVideoElement";
import { CreateAudioElement } from "../../functions/CreateAudioElement";
import { AppendPowerpointElement } from "../../functions/AppendPowerpointElement";
import { CreateScreenCaptureDevice } from "../../functions/CreateScreenCaptureDevice";

export const PlaybackContext = createContext<PlaybackContextType>(null!);

export const PlaybackProvider: FC<ReactElementProps> = ({ children }: ReactElementProps) => {
	const [ media, setMedia ] = useState<AudioElement | VideoElement | null | undefined>();
	const [ , setIframe ] = useState<HTMLIFrameElement>();
	const [ type, setType ] = useState<"audio" | "video" | "iframe" | null>();
	const { room } = useVideoContext();
	const [ isPlaying, setIsPlaying ] = useState<PlaybackState>("notready");
	const [ localTracks, setLocalTracks ] = useState<(LocalAudioTrack | LocalVideoTrack)[]>([]);

	const handleCanPlay = async () => {
		console.info("[CLIENT:PlaybackProvider:handleCanPlay] Media Ready to Play");
		if(room) {
			if(media && type === "audio") {
				const stream = media.captureStream();
				const [ audioStream ] = stream.getAudioTracks();
				const bgAudioTrack = new LocalAudioTrack(audioStream, { name: `audio-playback-${uuidv4()}`, logLevel: "off" });
				await room.localParticipant.publishTrack(bgAudioTrack);
				setLocalTracks([ bgAudioTrack ]);
				console.info("[CLIENT:PlaybackProvider:handleCanPlay] Triggering Media Playback");
				media.play();
			}
			else if (media && type === "video") {
				const stream = media.captureStream();
				const [audioStream] = stream.getAudioTracks();
				const [videoStream] = stream.getVideoTracks();
				const bgAudioTrack = new LocalAudioTrack(audioStream, { name: `audio-playback-${uuidv4()}`, logLevel: "off" });
				const bgVideoTrack = new LocalVideoTrack(videoStream, { name: `video-playback-${uuidv4()}`, logLevel: "off" });
				await Promise.allSettled([
					room.localParticipant.publishTrack(bgAudioTrack),
					room.localParticipant.publishTrack(bgVideoTrack)
				]);

				setLocalTracks([ bgAudioTrack, bgVideoTrack ]);
				console.info("[CLIENT:PlaybackProvider:handleCanPlay] Triggering Media Playback");
				media.play();

			}
		}
	}

	useEffect(() => {
		if (media && room && type) {
			setIsPlaying("notready");

			const handlePlay = () => {
				setIsPlaying("playing");
				console.info("[CLIENT:PlaybackProvider:handlePlay] Media Playing");
			}
			const handlePause = () => {
				localTracks.forEach(track => {
					track.stop();
					room.localParticipant.unpublishTrack(track);
				});
				setIsPlaying("paused");
				console.info("[CLIENT:PlaybackProvider:handlePause] Media Paused");
			};

			const handleEnded = () => {
				localTracks.forEach(track => {
					room.localParticipant.unpublishTrack(track);
				});

				console.info("[CLIENT] Disconnecting From Video Room");
				room.disconnect();

				setMedia(null);
				setType(null);
				setIsPlaying("ended");
				setLocalTracks([]);
				console.info("[CLIENT:PlaybackProvider:handleEnded] Media Ended");
			};

			const handleError = () => console.info("[CLIENT:PlaybackProvider:handleError] Media Error Triggering onError Client Callback");
			const handleSuspend = () => console.info("[CLIENT:PlaybackProvider:handleSuspend] Media Suspended");
			const handleStalled = () => console.info("[CLIENT:PlaybackProvider:handleStalled] Media Stalled");
			const handleCanPlayThrough = () => console.info("[CLIENT:PlaybackProvider:handleCanPlayThrough] Media can now be played through");
			const handleDurationChange = () => console.info("[CLIENT:PlaybackProvider:handleDurationChange] Media Duration is now changed?");

			media.addEventListener("canplay", handleCanPlay);
			media.addEventListener("play", handlePlay);
			media.addEventListener("ended", handleEnded);
			media.addEventListener("pause", handlePause);
			media.addEventListener("error", handleError);
			media.addEventListener("suspend", handleSuspend);
			media.addEventListener("stalled", handleStalled);
			media.addEventListener("canplaythrough", handleCanPlayThrough);
			media.addEventListener("durationchange", handleDurationChange);

			if (media.readyState >= media.HAVE_FUTURE_DATA) {
				handleCanPlay();
			}

			return () => {
				media.removeEventListener("canplay", handleCanPlay);
				media.removeEventListener("play", handlePlay);
				media.removeEventListener("ended", handleEnded);
				media.removeEventListener("pause", handlePause);
				media.removeEventListener("error", handleError);
				media.removeEventListener("suspend", handleSuspend);
				media.removeEventListener("stalled", handleStalled);
				media.removeEventListener("canplaythrough", handleCanPlayThrough);
				media.removeEventListener("durationchange", handleDurationChange);
			};
		}
	}, [room, media, type]);

	useEffect(() => {
		console.info("[CLIENT:PlaybackProvider:useEffect]", room, localTracks, type);
		if(room && localTracks && type === "iframe") {
			localTracks.forEach((track) => {
				room.localParticipant.publishTrack(track);
			});
		}
	}, [ room, type, localTracks])

	const togglePlayback = (): void => {
		console.info(`[CLIENT:PlaybackProvider:togglePlayback] Media isPlaying ${isPlaying}`);
		if (media && (isPlaying === "playing" || isPlaying === "paused")) {
			isPlaying === "playing" ? media.pause() : handleCanPlay();
		}
	};

	const adjustVolume = (volume: number): void => {
		if (media) {
			console.info(`[CLIENT:PlaybackProvider:adjustVolume] Adjust Volume Playback. Audio Volume ${media.volume}`);
			media.volume = volume;
		}
	}

	const loadFromUrl = useCallback(async ({ url, mimeType, opts }: LoadFromUrlPayload): Promise<void> => {
		console.info(`[CLIENT:PlaybackProvider:loadFromUrl] Loading Media from ${url}`);
		if (url && url !== "") {
			setIsPlaying("notready");

			if(mimeType && mimeType.split("/")[0] === "audio"){
				const audioVar = CreateAudioElement(url, opts?.loop);
				setMedia(audioVar);
				setType("audio");
			}
			else if(mimeType && mimeType.split("/")[0] === "video") {
				const videoVar = AppendVideoElement(url, opts?.loop) as VideoElement;
				setMedia(videoVar);
				setType("video");
			}
			else if(mimeType && mimeType.split("/")[0] === "application") {
				const powerpoint = AppendPowerpointElement(url);
				setIframe(powerpoint);
				setType("iframe");
				const stream = await CreateScreenCaptureDevice();
				const [ videoStream ] = stream.getVideoTracks();
				const bgVideoTrack = new LocalVideoTrack(videoStream, { name: `video-playback-${uuidv4()}`, logLevel: "off" });
				setLocalTracks([ bgVideoTrack ]);
			}
			else {
				const audioVar = CreateAudioElement(url, opts?.loop);
				setMedia(audioVar);
				setType("audio");
			}
		}
	}, []);

	useEffect(() => {
		window.playbackProvider = { ...window.playbackProvider, isPlaying };
	}, [isPlaying]);

	return <PlaybackContext.Provider value={{ loadFromUrl, togglePlayback, adjustVolume, isPlaying }}>{children}</PlaybackContext.Provider>;
};