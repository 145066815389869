import { useContext } from "react";
import { PlaybackContext } from "../../components/PlaybackProvider/PlaybackProvider";
import { PlaybackContextType } from "../../types/playback-provider";

export const usePlaybackContext = (): PlaybackContextType => {
	const context = useContext(PlaybackContext);
	if (!context) {
		throw new Error("usePlaybackContext must be used within a PlaybackProvider");
	}
	return context;
}