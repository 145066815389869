import { FC, useEffect } from "react";
import { useConnectionOptions } from "../../hooks/useConnectionOptions/useConnectionOptions";
import { useLogContext } from "../../hooks/useLogContext/useLogContext";
import { MediaPlayer } from "../MediaPlayer/MediaPlayer";
import { PlaybackProvider } from "../PlaybackProvider/PlaybackProvider";
import { VideoProvider } from "../VideoProvider";

export const MediaPlayerContainer: FC = () => {
	const connectionOptions = useConnectionOptions();
	const { rum, connect, providerReady } = useLogContext();

	useEffect(() => {
		if (!rum) {
			connect();
		}

		rum?.recordPageView(window.location.pathname);
	}, [connect, window.location, rum, providerReady]);

	const onError = (error: any) => {
		console.error("[CLIENT:VideoProviderError] ", error);
		rum?.recordError(error);
	};

	return (
		<VideoProvider options={connectionOptions} onError={onError}>
			<PlaybackProvider>
				<MediaPlayer />
			</PlaybackProvider>
		</VideoProvider>);
};