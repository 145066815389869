import { Room, TwilioError } from "twilio-video";
import { useEffect } from "react";
import { Callback } from "../../../types";

export const useHandleRoomDisconnection = (
	room: Room | null,
	onError: Callback,
): void => {
	useEffect(() => {
		if (room) {
			const onDisconnected = (_: Room, error: TwilioError) => {
				if (error?.code === 53118) {
					console.info("[CLIENT:useHandleRoomDisconnection] The event has been ended by the host");
				} else if (error) {
					console.error(`[CLIENT:useHandleRoomDisconnection] ${error.message} `, error);
					onError(error);
				}
				else {
					console.info("[CLIENT:useHandleRoomDisconnection] You have been disconnected");
				}
			};

			room.on("disconnected", onDisconnected);
			return () => {
				room.off("disconnected", onDisconnected);
			};
		}
	}, [
		room,
		onError
	]);
}
