import type { ReportHandler } from "web-vitals";

export const ReportWebVitals = (onPerfEntry?: ReportHandler): void => {
	if (onPerfEntry && onPerfEntry instanceof Function) {
		import("web-vitals")
			.then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
				getCLS(onPerfEntry, true);
				getFID(onPerfEntry);
				getFCP(onPerfEntry);
				getLCP(onPerfEntry, true);
				getTTFB(onPerfEntry);
				return true;
			})
			.catch(() => console.error("[CLIENT:ReportWebVitals] web vitals import error"));
	}
};
