import { render } from "react-dom";
import { StrictMode } from "react";
import { ReportWebVitals } from "./core/ReportWebVitals";
import { Router } from "./core/Router";

render(
	<StrictMode>
		<Router />
	</StrictMode>,
	document.querySelector("#root")
);

/**
 * If you want to start measuring performance in your app, pass a function
 * to log results (for example: reportWebVitals(console.info))
 * or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
 */
// eslint-disable-next-line no-console
ReportWebVitals(console.info);
