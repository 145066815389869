import { FC, useEffect } from "react";
import { usePlaybackContext } from "../../hooks/usePlaybackContext/usePlaybackContext";
import { useVideoContext } from "../../hooks/useVideoContext/useVideoContext";

export const MediaPlayer: FC = () => {
	const { room, isConnecting, connect } = useVideoContext();
	const playbackProvider = usePlaybackContext();

	useEffect(() => {
		window.playbackProvider = { ...playbackProvider };
		window.videoProvider = { room: (room && room !== null) ? room : undefined, isConnecting, connect };
	}, []);

	return (<>
		<div style={{ display: "flex", position: "relative", flex: "1 1 auto", flexDirection: "row", flexWrap: "nowrap" }}>
			<div style={{ display: "flex", flex: "1 1 auto", flexDirection: "column", flexWrap: "nowrap" }} id="container"></div>
		</div>
		<canvas id="canvasContainer" style={{ width: "1280px", height: "720px" }}></canvas>
	</>);
}