export const CreateScreenCaptureDevice = async () => {
	const supported = navigator.mediaDevices.getSupportedConstraints();
	console.info("supported", supported);
	const stream = await navigator.mediaDevices.getDisplayMedia({
		audio: true,
		video: {
			width: 2080,
			height: 720,
			frameRate: 24
		}
	} as any);

	console.info("getCapabilities", stream.getVideoTracks()[0].getCapabilities());
	console.info("getConstraints", stream.getVideoTracks()[0].getConstraints());

	return stream;
}