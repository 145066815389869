import { ReactNode, createContext, useCallback, FC, useEffect } from "react";
import { ConnectOptions, Room } from "twilio-video";
import { ErrorCallback } from "../../types/twilio-video";
import { useHandleRoomDisconnection } from "./useHandleRoomDisconnection/useHandleRoomDisconnection";
import { useHandleTrackPublicationFailed } from "./useHandleTrackPublicationFailed/useHandleTrackPublicationFailed";
import { useRoom } from "./useRoom/useRoom";

/*
 *  The hooks used by the VideoProvider component are different than the hooks found in the 'hooks/' directory. The hooks
 *  in the 'hooks/' directory can be used anywhere in a video application, and they can be used any number of times.
 *  the hooks in the 'VideoProvider/' directory are intended to be used by the VideoProvider component only. Using these hooks
 *  elsewhere in the application may cause problems as these hooks should not be used more than once in an application.
 */

export interface IVideoContext {
  room: Room | null;
  isConnecting: boolean;
  connect: (token: string) => Promise<void>;
  onError: ErrorCallback;
}

export const VideoContext = createContext<IVideoContext>(null!);

interface VideoProviderProps {
  options?: ConnectOptions;
  onError: ErrorCallback;
  children: ReactNode;
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const VideoProvider: FC<VideoProviderProps> = ({ options, children, onError = () => {} }: VideoProviderProps) => {
	const onErrorCallback: ErrorCallback = useCallback(
		error => {
			console.error(`[CLIENT:VideoProviderError] ${error.message} `, error);
			onError(error);
		},
		[onError]
	);

	const { room, isConnecting, connect } = useRoom(onErrorCallback, options);

	// Register callback functions to be called on room disconnect.
	useHandleRoomDisconnection( room, onError);
	// useRestartAudioTrackOnDeviceChange(localTracks);

	useHandleTrackPublicationFailed(room, onError);

	useEffect(() => {
		window.videoProvider = { ...window.videoProvider, room: (room && room !== null) ? room : undefined, isConnecting };
	}, [ room, isConnecting ]);

	return (
		<VideoContext.Provider
			value={{
				room,
				isConnecting,
				connect,
				onError: onErrorCallback
			}}
		>{children}</VideoContext.Provider>
	);
}
